import { defineMessages, useIntl } from 'react-intl';
import Head from 'next/head';
import ChurchSelectTemplate from '@components/templates/donate/church-select';
export const titleMessage = defineMessages({
  title: {
    id: 'p0nCGh',
    defaultMessage: 'Donate',
    description: 'Title for Donate page.',
  },
});
const ChurchSelectPage = () => {
  const intl = useIntl();
  return (
    <>
      <Head>
        <title>
          {`AdventistGiving - ${intl.formatMessage(titleMessage.title)}`}
        </title>
      </Head>
      <ChurchSelectTemplate />
    </>
  );
};
export default ChurchSelectPage;
