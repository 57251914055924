import { Combobox } from '@headlessui/react';
import { InputHTMLAttributes, useState } from 'react';
import uniqBy from 'lodash.uniqby';
import { FormattedMessage } from 'react-intl';
import ComboboxInputSearch from '../../atoms/combobox-input-search/combobox-input-search';
import ChurchSearchOption from '../church-search-option/church-search-option';
import { Organization } from '@models/organization';
import { Pagination } from '@models/pagination';
import { Heading } from '@components/atoms/heading/heading';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  initOpen?: boolean;
  value?: string;
  onItemSelected: (value: Organization | undefined) => void;
  organizations?: Pagination<Organization>;
  label?: string;
}

const ChurchSearch = ({
  initOpen,
  organizations,
  onItemSelected,
  label,
  ...props
}: Props) => {
  const [customOpen, setCustomOpen] = useState(initOpen ?? false);
  const orgRecords =
    organizations && organizations.records
      ? uniqBy(
          organizations.records.sort((a, b) =>
            a.status.localeCompare(b.status)
          ),
          'id'
        )
      : [];
  return (
    <Combobox value={props.value} onChange={(v) => onItemSelected(v as any)}>
      {({ open }) => (
        <div className="flex flex-col relative">
          <label>
            {label && (
              <Heading variant="h5" color="nad-blue" className="mb-4 sm:text-l">
                {label}
              </Heading>
            )}
            {!label && (
              <span className="sr-only">
                <FormattedMessage
                  id="5a+twV"
                  defaultMessage="Search for a church."
                  description="Screen reader label for church search."
                />
              </span>
            )}
            <ComboboxInputSearch
              autoFocus
              onClick={(e) => {
                setCustomOpen(true);
              }}
              {...props}
              data-testid="church-search-input"
            />
          </label>
          {(open || customOpen) && orgRecords.length > 0 && (
            <Combobox.Options
              static
              className="absolute z-10 bg-white w-full max-h-96 top-full divide-solid divide-y border-nad-light-blue border-[3px] border-t-0 overflow-y-scroll"
            >
              {orgRecords.map((o) => (
                <ChurchSearchOption key={o.id} organization={o} />
              ))}
            </Combobox.Options>
          )}
        </div>
      )}
    </Combobox>
  );
};

export default ChurchSearch;
