import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import { defineMessages } from '@formatjs/intl';
import { useIntl } from 'react-intl';
import { SimpleTemplateContainer } from '..';
import { useGetOrganization, useOrganizations } from '@hooks/use-organization';
import ChurchSearch from '@components/molecules/church-search/church-search';
import { Organization } from '@models/organization';
import BackLink from '@components/molecules/back-link/back-link';
import { ChurchEnrollmentModal } from '@components/organisms/church-enrollment-modal/church-enrollment-modal';
import { useEnvelope } from '@hooks/use-envelope';
import { Pagination } from '@models/pagination';
import { Heading } from '@components/atoms/heading/heading';

const messages = defineMessages({
  title: {
    id: 'O5zBmU',
    defaultMessage: 'Select a church to begin donation',
    description: 'Page title/heading only shown to screen readers.',
  },
  placeholder: {
    id: 'ykAveR',
    defaultMessage: 'Where would you like to donate to?',
    description:
      'Placeholder for input field on donate page to select a church.',
  },
});

export const ChurchSelectTemplate = () => {
  const intl = useIntl();
  const [isEnrollmentModalOpen, setIsEnrollmentModalOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<Organization>();
  const [search, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [cachedOrgs, setCachedOrgs] = useState<Pagination<Organization>>();
  const { data: organizations, isLoading } = useOrganizations({
    name: debouncedSearchValue,
  });
  const activeOrgId =
    selectedOrg && selectedOrg.status === 'active' ? selectedOrg.id : undefined;
  // prefetch envelope data for smoother transition to next page
  useGetOrganization({ id: activeOrgId });
  useEnvelope({ orgId: activeOrgId });
  const router = useRouter();
  const debouncedSearch = useMemo(
    () =>
      debounce((val) => {
        setDebouncedSearchValue(val);
      }, 200),
    []
  );
  useEffect(() => {
    if (!isLoading) {
      setCachedOrgs(organizations);
    }
  }, [isLoading, organizations]);
  return (
    <SimpleTemplateContainer isContainer>
      <div className="w-full">
        <div className="relative mx-auto py-4 mb-16">
          <Heading variant="h2" as="h1" color="nad-blue" className="sr-only">
            {intl.formatMessage(messages.title)}
          </Heading>
          <div className="flex flex-col gap-4 lg:flex-row relative">
            <div className="container-sm lg:absolute lg:left-0">
              <BackLink />
            </div>
            <div className="container-sm">
              <ChurchSearch
                value={search}
                organizations={cachedOrgs}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  debouncedSearch(e.target.value);
                }}
                onItemSelected={(organization) => {
                  if (!organization) {
                    return;
                  }
                  setSearchValue(organization.name);
                  setSelectedOrg(organization);
                  if (organization.status === 'active') {
                    router.push(`/donate/${organization.id}`);
                  } else {
                    setIsEnrollmentModalOpen(true);
                  }
                }}
                label={intl.formatMessage(messages.placeholder)}
              />
            </div>
          </div>
        </div>
      </div>
      <ChurchEnrollmentModal
        isOpen={isEnrollmentModalOpen}
        close={() => setIsEnrollmentModalOpen(false)}
        organization={selectedOrg}
        data-testid="church-enrollment-modal"
      ></ChurchEnrollmentModal>
    </SimpleTemplateContainer>
  );
};
export default ChurchSelectTemplate;
