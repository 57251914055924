import { InputHTMLAttributes, useEffect, useRef } from 'react';
import { Combobox } from '@headlessui/react';
import { defineMessages, useIntl } from 'react-intl';
import { IconClose, IconSearch } from '../icons';

const messages = defineMessages({
  placeholder: {
    id: '2HZiZ9',
    defaultMessage: 'Type here to search for a church',
    description: 'Placeholder text for church search field',
  },
});

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  onClose?: () => void;
  customOpen?: boolean;
}

const ComboboxInputSearch = ({ value, onClose, ...props }: Props) => {
  const intl = useIntl();
  const input = useRef<HTMLInputElement>(null);
  if (!props.placeholder) {
    props.placeholder = intl.formatMessage(messages.placeholder);
  }
  const handleCloseIcon = () => {
    if (input.current) {
      // force onChange event
      // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        HTMLInputElement.prototype,
        'value'
      )?.set;
      if (nativeInputValueSetter) {
        nativeInputValueSetter.call(input.current, '');
        const event = new Event('input', { bubbles: true });
        input.current.dispatchEvent(event);
      }
    }
    onClose && onClose();
  };
  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  });
  return (
    <div className="w-full border-nad-light-blue border-[3px] px-6 py-4 relative flex flex-row items-center">
      <Combobox.Input
        {...props}
        ref={input}
        autoComplete="off"
        displayValue={() => value || ''}
        onChange={props.onChange!}
        className="w-full border-transparent px-0 py-1 focus:border-transparent focus:ring-0 form-input text-s placeholder:text-nad-alps-night-2 placeholder:font-serif font-serif text-nad-blue"
      />
      <Combobox.Button>
        {({ open }) => (
          <>
            {(value || open) && (
              <IconClose
                width={20}
                height={20}
                onClick={handleCloseIcon}
                data-testid="input-search-close-icon"
              />
            )}
            {!value && !open && (
              <IconSearch
                width={24}
                height={24}
                data-testid="input-search-magnifying-glass-icon"
              />
            )}
          </>
        )}
      </Combobox.Button>
    </div>
  );
};

export default ComboboxInputSearch;
