import { Combobox } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';
import { Organization } from '@models/organization';
import { Text } from '@components/atoms/text/text';
import { IconInterrogation } from '@components/atoms/icons';
import { formatAddress } from '@hooks/use-organization';

interface Props {
  organization: Organization;
  onItemSelected?: (item: Organization) => void;
}

const ChurchSearchOption = ({ organization, onItemSelected }: Props) => {
  const isEnrolled = organization.status === 'active';
  return (
    <Combobox.Option value={organization} data-testid="church-search-option">
      {({ active, selected }) => (
        <div
          className={`w-full text-left px-6 py-2 relative cursor-pointer ${
            active ? 'bg-nad-light-blue' : ''
          }`}
          onClick={(e) => {
            if (onItemSelected) {
              onItemSelected(organization);
            }
          }}
        >
          <Text
            size="S"
            className="font-serif"
            color={isEnrolled ? 'nad-blue' : 'nad-alps-night-2'}
          >
            {organization.name}
          </Text>
          <Text
            size="XS"
            className="font-sans"
            color={isEnrolled ? 'nad-alps-night' : 'nad-alps-night-2'}
          >
            {formatAddress(organization, true)}
          </Text>
          {!isEnrolled && (
            <div className="flex mt-2 sm:mt-0 sm:absolute right-4 bottom-2 sm:items-center sm:justify-center space-x-1.5">
              <Text size="XS" className="font-sans" color="nad-alps-night-2">
                <FormattedMessage
                  id="k/Ypa/"
                  defaultMessage="Not Enrolled"
                  description="Status label for a church that is not currently active."
                />
              </Text>
              <IconInterrogation width={17} height={17} />
            </div>
          )}
        </div>
      )}
    </Combobox.Option>
  );
};

export default ChurchSearchOption;
